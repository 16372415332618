<template>
  <b-card-code
    no-body
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      :title="`${recordToDelete.isPermanent ? 'Are you sure you want to permanently delete this record?' : 'Are you sure you want to delete this record?'}`"
      centered
      ok-title="Delete"
      @ok="deleteModalHandler"
    >
      <div>
        {{ recordToDelete.isPermanent ? 'Permanently deleted records cannot be recovered later.' : 'In case you might want to recover it later, you can enable the "With deleted" toggle to find it and either recover or permanently delete it.' }}
      </div>
    </b-modal>
    <b-modal
      id="licence-info"
      ref="licence-info"
      :title="selectedHardwareModalInfo.name ? 'Licence - ' + selectedHardwareModalInfo.name : 'Licence'"
      size="md"
      :ok-title="isClient ? 'Deallocate' : 'Edit'"
      cancel-title="Close"
      centered
      @ok="handleModalClick(selectedHardwareModalInfo)"
    >
      <div
        v-if="selectedHardwareModalInfo.category"
        class="view-modal"
      >
        <div class="mb-1 align-items-center view-modal__section-title">
          <feather-icon
            icon="ListIcon"
            width="18"
            height="18"
          />
          <h4>
            Licence details
          </h4>
        </div>
        <p v-if="selectedHardwareModalInfo.category">
          Category: <span>{{ selectedHardwareModalInfo.category.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.level">
          Level: <span>{{ selectedHardwareModalInfo.level.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.type">
          Type: <span>{{ selectedHardwareModalInfo.type.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.site">
          Site: <span>{{ selectedHardwareModalInfo.site.name }}</span>
        </p>
        <div
          v-if="selectedHardwareModalInfo.client || selectedHardwareModalInfo.reseller || selectedHardwareModalInfo.distributor || selectedHardwareModalInfo.masterDistributor"
          class="view-modal__owners"
        >
          <div class="mb-1 align-items-center view-modal__section-title">
            <feather-icon
              icon="UsersIcon"
              width="18"
              height="18"
            />
            <h4>Owner details</h4>
          </div>
          <p v-if="selectedHardwareModalInfo.client">
            Client: <span>{{ selectedHardwareModalInfo.client.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.reseller">
            Reseller: <span>{{ selectedHardwareModalInfo.reseller.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.distributor">
            Distributor: <span>{{ selectedHardwareModalInfo.distributor.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.masterDistributor">
            Master Distributor: <span>{{ selectedHardwareModalInfo.masterDistributor.name }}</span>
          </p>
        </div>
        <div class="mb-1 align-items-center view-modal__section-title">
          <feather-icon
            icon="InfoIcon"
            width="18"
            height="18"
          />
          <h4>Additional details</h4>
        </div>
        <div>
          <b-badge
            pill
            class="license-badge mb-1"
            :variant="selectedHardwareModalInfo.statusColor"
          >
            {{ selectedHardwareModalInfo.status.name }}
          </b-badge>
          <div class="licence-modal-switch">
            <p>Enabled/Disabled: </p>
            <b-form-checkbox
              v-model="selectedHardwareModalInfo.is_enable"
              name="is_enable"
              switch
              inline
              disabled
            />
          </div>
          <p v-if="selectedHardwareModalInfo.created_at">
            Creation Date: <span>{{ new Date(selectedHardwareModalInfo.created_at).toDateString() }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.activation_at">
            Activation Date: <span>{{ new Date(selectedHardwareModalInfo.activation_at).toDateString() }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.renewal_at">
            Renewal Date: <span>{{ new Date(selectedHardwareModalInfo.renewal_at).toDateString() }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.expiry_at">
            Expiry Date: <span>{{ new Date(selectedHardwareModalInfo.expiry_at).toDateString() }}</span>
          </p>
        </div>
      </div>
    </b-modal>
    <b-row
      class="px-2 mb-2"
    >
      <b-col
        v-if="$can('create', 'licences')"
        order-md="2"
        order="1"
        class="text-right"
      >
        <div
          v-if="$can('delete', 'licences')"
          label-class="label-class"
          class="with-deleted-checkbox"
        >
          <span>With deleted</span>
          <b-form-checkbox
            class="mr-0"
            switch
            inline
            @change="setFilterWithTrashed"
          />
        </div>
        <b-button
          v-if="$can('additional-action-delete', 'licences')"
          variant="primary"
          class="mr-2"
          @click="toggleFilterOnlyTrashed"
        >
          <span v-if="this.onlyTrashed">Without deleted</span>
          <span v-else>Only deleted</span>
        </b-button>
        <div class="site-hardware-actions">
          <b-button
            to="/licence/create"
            variant="primary"
          >
            Add Licence
          </b-button>
          <b-button
            to="/licence/create/bulk"
            variant="primary"
          >
            Add Bulk Licence
          </b-button>
          <b-button
            variant="primary"
          >
            <download-csv
              :data="itemsForExport"
            >

              Export View
            </download-csv>
          </b-button>
        </div>
      </b-col>

      <b-col
        md="6"
        order-md="1"
        order="2"
      >
        <b-form @submit.prevent="searchRows()">
          <p v-if="companyId">
            Company: <a
              :href="`/company-management/summary/${companyId}`"
            >
              {{ companyName }}
            </a>
          </p>
          <b-form-row class="align-items-end">
            <b-col>
              <feather-icon
                v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
                v-b-tooltip.hover
                icon="XIcon"
                title="Reset filters"
                size="24"
                class="text-body align-middle mr-25"
                @click="handleReset"
              />
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
    <table-mobile-solo
      :search="searchValue"
      :handle-search="handleMobileSearch"
      :mobile-search-fields="mobileSearchFields"
      :items="items"
      :fields="fields"
      :pagination="{
        pagination: pagination,
        perPage: perPage,
        currentPage: page,
        pageSizeOptions: pageSizeOptions,
        enabled: true,
        perPageChanged: perPageChanged,
        changePage: changePage,
      }"
      :rules="{
        category(value) {
          return value.name
        },
        type(value) {
          return value.name
        },
        level(value) {
          return value.name
        },
        masterDistributor(value) {
          return value.masterDistributor.name ? value.masterDistributor.name : '-'
        },
        distributor(value) {
          return value.distributor ? value.distributor.name : '-'
        },
        reseller(value) {
          return value.reseller ? value.reseller.name : '-'
        },
        client(value) {
          return value.client ? value.client.name : '-'
        },
        site(value) {
          return value.site ? value.site.name : '-'
        },
      }"
      :key-value="'number'"
      :actions="[{
        label: 'Deallocate',
        type: 'primary',
        icon: 'Edit2Icon',
        visible: () => isClient,
        click: (item) => deattach(item),
        disabled: (item) => !item.site,
      }, {
        label: 'Edit',
        type: 'primary',
        icon: 'Edit2Icon',
        visible: () => $can('update', 'licences') && !isClient,
        click: ({id}) => toEdit(id),
      }, {
        label: 'Delete',
        type: 'danger',
        icon: 'TrashIcon',
        visible: ({is_deleted}) => !is_deleted && $can('delete', 'licences'),
        click: ({id}) => deleteHandler(id),
      }, {
        label: 'Permanently delete',
        type: 'danger',
        icon: 'TrashIcon',
        visible: ({is_deleted}) => is_deleted && $can('delete', 'licences'),
        click: ({id}) => deleteHandler(id, true),
      }, {
        label: 'Recover',
        type: 'primary',
        icon: 'RefreshCcwIcon',
        visible: ({is_deleted}) => is_deleted && $can('delete', 'licences'),
        click: ({id}) => recoverRow(id),
      }]"
    />
    <b-card-body>

      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        compact-mode
        :columns="fields"
        :rows="items"
        :total-rows="pagination.total"
        class="licence-table hide-mobile-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
          setCurrentPage: page
        }"
        @on-sort-change="onSortChange"
        @on-column-filter="columnFilterFn"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="isClient"
                  :disabled="!props.row.site"
                  @click.prevent="deattach(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Deallocate</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('update', 'licences') && !isClient"
                  @click.prevent="toEdit(props.row.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!props.row.is_deleted && $can('delete', 'licences')"
                  @click.prevent="deleteHandler(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'licences')"
                  @click.prevent="deleteHandler(props.row.id, true)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Permanently delete</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.is_deleted && $can('delete', 'licences')"
                  @click.prevent="recoverRow(props.row.id)"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                  />
                  <span>Recover</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'number'"
            @click.prevent="onSelectHardwareInfo(props.row.id)"
          >
            <a href="">
              {{ props.formattedRow[props.column.field] }}
            </a>
          </span>
          <span
            v-else-if="props.column.field === 'masterDistributor.name'"
          >
            <router-link
              v-if="props.formattedRow['masterDistributor'] && props.formattedRow['masterDistributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['masterDistributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['masterDistributor']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'distributor.name'"
          >
            <router-link
              v-if="props.formattedRow['distributor'] && props.formattedRow['distributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['distributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['distributor']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'reseller.name'"
          >
            <router-link
              v-if="props.formattedRow['reseller'] && props.formattedRow['reseller'].is_link"
              :to="`/company-management/summary/${props.formattedRow['reseller'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['reseller']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'client.name'"
          >
            <router-link
              v-if="props.formattedRow['client'] && props.formattedRow['client'].is_link"
              :to="`/company-management/summary/${props.formattedRow['client'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['client']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'site.name'"
          >
            <router-link
              v-if="props.formattedRow['site']"
              :to="`/site/${props.formattedRow['site'].id}/hardware/index`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'type' || props.column.field === 'category' || props.column.field === 'level'"
          >
            <span v-if="props.row[props.column.field]">
              {{ props.row[props.column.field].name }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              pill
              :variant="setStatusColor(props.formattedRow[props.column.field].id)"
            >
              <span class="text-white">
                {{ props.formattedRow[props.column.field].name }}
              </span>
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'is_enable'">
            <b-form-checkbox
              v-model="props.formattedRow[props.column.field]"
              name="is_enable"
              :disabled="enableActionInProgress"
              switch
              inline
              @change="changeStatus(props.formattedRow)"
            />
          </span>
          <span v-else-if="props.column.field === 'remaining_units'">
            <span
              v-if="props.row.remaining_units_label"
              v-b-tooltip.hover
              :title="`${props.row.remaining_units_label}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody,
  BPagination,
  BButton,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BForm,
  BModal,
  BFormRow,
  BSpinner,
  BBadge,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import downloadCsv from 'vue-json-csv'
import flatPickr from 'flatpickr'

import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/material_blue.css'
import { ref, watch } from '@vue/composition-api'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../axios-resolver'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCardCode,
    BCardBody,
    BButton,
    BPagination,
    BRow,
    BCol,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BForm,
    BFormCheckbox,
    BFormRow,
    BSpinner,
    VueGoodTable,
    downloadCsv,
    TableMobileSolo,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      mobileTableOn: window.innerWidth < 745,
      isLoading: true,
      uppdateRouteName: 'licence-update',
      enableActionInProgress: false,
      currentRoute: this.$route.path,

      deleteErrorPopUpInfo: {},
      isClient: '',
      selectedHardwareModalInfo: {},

      recordToDelete: {
        id: null,
        isPermanent: false,
      },

      mobileSearchFields: {
        number: '',
        'client.name': '',
        'site.name': '',
      },
      searchValue: '',
      fields: [
        {
          field: 'id',
          hidden: true,
        },
        {
          field: 'number',
          label: 'Licence',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'category',
          label: 'Product',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'type',
          label: 'Type',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'level',
          label: 'Level',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'masterDistributor',
          hidden: true,
        },
        {
          field: 'masterDistributor.name',
          mobileSpecial: 'masterDistributor',
          label: 'Master Dist',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'distributor',
          hidden: true,
        },
        {
          field: 'distributor.name',
          mobileSpecial: 'distributor',
          label: 'Distributor',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'reseller',
          hidden: true,
        },
        {
          field: 'reseller.name',
          mobileSpecial: 'reseller',
          label: 'Reseller',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'client',
          hidden: true,
        },
        {
          field: 'client.name',
          mobileSpecial: 'client',
          label: 'Client',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'site',
          hidden: true,
        },
        {
          field: 'site.name',
          mobileSpecial: 'site',
          label: 'Site',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'created_at',
          label: 'Creation',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Creation',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'activation_at',
          label: 'Activation',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Activation',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'renewal_at',
          label: 'Renewal',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Renewal',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'expiry_at',
          label: 'Expiry',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Expiry',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'remaining_units',
          label: 'Units Remaining',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'status',
          label: 'Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'is_enable',
          label: 'Active',
          filterOptions: {
            enabled: false,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'licences') && !this.$can('delete', 'licences'),
        },
      ],
    }
  },
  setup() {
    const companyName = ref('')
    const companyId = ref('')

    watch(companyName, companyId, () => {
      if (this.$route.query.companyId) {
        this.companyId = this.$route.query.companyId
        const company = this.getCompany(this.$route.query.companyId)
        this.companyName = company.name
      } else {
        this.companyName = ''
      }
    })
    return {
      companyName: '',
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.isClient = userData.roles.includes('client')

    // watch width of the window
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState('licence', {
      items: 'items',
      permissions: 'permissions',
      levels: 'levels',
      statuses: 'statuses',
      categories: 'categories',
      types: 'types',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
    }),
    itemsForExport() {
      const arr = JSON.parse(JSON.stringify(this.items))
      arr.map(item => {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const property in item) {
          // eslint-disable-next-line no-param-reassign
          if (typeof item[property] === 'object') {
            if (item[property] && item[property].name) {
              // eslint-disable-next-line no-param-reassign
              item[property] = item[property].name
            }
          }
        }
        return item
      })
      return arr
    },
    page() {
      return this.$route.query?.params || {}
    },
  },
  watch: {
    async $route(to) {
      const additionalPathname = to.fullPath.replace(to.path, '')
      if (!additionalPathname) {
        await this.clearAllData()
        this.getData()
        this.companyName = ''
      }
    },

    recordToDelete(val) {
      if (val.id) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
    items() {
      this.fields = this.fields.map(item => {
        if (item.label === 'Product') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.categories] } }
        }
        if (item.label === 'Type') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.types] } }
        }
        if (item.label === 'Level') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.levels] } }
        }
        if (item.label === 'Status') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.statuses] } }
        }
        return item
      })
      this.setVisibleFields()
    },
  },
  async mounted() {
    const inputs = [
      'input[placeholder="Renewal"]',
      'input[placeholder="Activation"]',
      'input[placeholder="Creation"]',
      'input[placeholder="Expiry"]',
    ]
    inputs.forEach(input => {
      flatPickr(input, {
        dateFormat: 'Y-m-d',
        mode: 'range',
        rangeSeparator: ',',
        onChange(selectedDates, datestr) {
          this.notFullyDate = true
          const arrOfDates = datestr.split('to')
          if (arrOfDates.length > 1) {
            document.querySelector(input).value = `${`${arrOfDates[0].replace(/\s/g, '')},${arrOfDates[1].replace(/\s/g, '')}`}`
            this.notFullyDate = false
          }
        },
        allowInput: false,
      })
    })
    if (this.$route.query.companyId) {
      const company = await this.getCompany(this.$route.query.companyId)
      this.companyName = company.name
    }
  },
  methods: {
    ...mapActions('licence', {
      fetchItems: 'getItems',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      updateLicenceStatus: 'updateLicenceStatus',
      getCompany: 'getCompany',
      deleteRecord: 'deleteItem',
      recoverRecord: 'recoverItem',
    }),
    handleReset() {
      this.resetFilter()
      this.searchValue = ''
    },
    handleResize() {
      // reset filters on change with 745px (more or less)
      if (window.innerWidth < 745 && !this.mobileTableOn) {
        this.mobileTableOn = true
        this.resetFilter()
      } else if (window.innerWidth >= 745 && this.mobileTableOn) {
        this.mobileTableOn = false
        this.resetFilter()
      }
    },
    async deleteHandler(id, isPermanent = false) {
      this.recordToDelete = {
        id,
        isPermanent,
      }
    },
    async handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }

      this.columnFilterFn(search, true)
    },
    handleModalClick(selectedHardwareModalInfo) {
      if (this.isClient) {
        this.deattach(selectedHardwareModalInfo)
      } else {
        this.toEdit(selectedHardwareModalInfo.id)
      }
    },
    async deattach(licence) {
      const { site, id } = licence

      try {
        await axios.delete(`/site/${site.id}/licences`, {
          params: {
            licenceIds: [id],
          },
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The licence has been removed from the site',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.fetchItems()
      } catch (error) {
        const { status } = error.response

        if (status === 422) {
          const { fields } = error.response.data

          this.$refs.modelsCreate.setErrors(fields)
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      }
    },
    async deleteModalHandler() {
      const errorRes = await this.deleteRow(this.recordToDelete.id)
      this.deleteErrorPopUpInfo = errorRes
      this.recordToDelete = {
        id: null,
        isPermanent: false,
      }
    },
    async onSelectHardwareInfo(id) {
      const getStatusColor = statusId => {
        let colorVariant
        switch (statusId) {
          case 'un-allocated':
            colorVariant = 'info'
            break
          case 'allocated':
            colorVariant = 'warning'
            break
          case 'activated':
            colorVariant = 'success'
            break
          case 'renewed':
            colorVariant = 'primary'
            break
          case 'ceasing':
            colorVariant = 'secondary'
            break
          case 'expired':
            colorVariant = 'danger'
            break
          default:
            colorVariant = 'danger'
        }
        return colorVariant
      }
      try {
        const { data } = await axios.get(`/licence/${id}/show`)
        this.selectedHardwareModalInfo = { ...data.data, id, statusColor: getStatusColor(data.data.status.id) }
        this.$refs['licence-info'].show()
      } catch (error) {
        console.warn('Error while fetching licence info', error)
      }
    },
    ...mapMutations('licence', ['RESET_STATE']),
    dateRangeFilter(data, filterString) {
      const dateRange = filterString.split(',')
      const startDate = Date.parse(dateRange[0])
      const endDate = Date.parse(dateRange[1])
      // eslint-disable-next-line no-return-assign, no-param-reassign
      return (data = Date.parse(data) >= startDate && Date.parse(data) <= endDate)
    },
    setVisibleFields() {
      this.fields = this.fields.map(item => {
        if (item.field === 'masterDistributor.name' && !this.permissions.includes('masterDistributor')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'distributor.name' && !this.permissions.includes('distributor')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'reseller.name' && !this.permissions.includes('reseller')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'client.name' && !this.permissions.includes('client')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        return item
      })
    },
    async changeStatus(item) {
      this.enableActionInProgress = true
      try {
        await this.updateLicenceStatus({ ids: [item.id], is_enable: item.is_enable })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Server error',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.enableActionInProgress = false
      }
    },
    setStatusColor(statusName) {
      let colorVariant
      switch (statusName) {
        case 'un-allocated':
          colorVariant = 'info'
          break
        case 'allocated':
          colorVariant = 'warning'
          break
        case 'activated':
          colorVariant = 'success'
          break
        case 'renewed':
          colorVariant = 'primary'
          break
        case 'ceasing':
          colorVariant = 'secondary'
          break
        case 'expired':
          colorVariant = 'danger'
          break
        default:
          colorVariant = 'danger'
      }
      return colorVariant
    },
    rowStyleClassFn(row) {
      return row.is_deleted ? 'deleted-class' : ''
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .vgt-table{
    .vgt-left-align, .vgt-right-align{
      vertical-align: middle !important;
      text-align: left;
    }
  }
  .dark-layout{
    .vgt-select{
      background-color: #283046;
      border-color: #404656;
    }
    .table-selected-row-info-panel{
      background-color: #283046;
      border-color: #404656;
    }
  }
  .licence-table {
    .deleted-class {
      .vgt-checkbox-col {
        background-color: rgba(255, 0, 0, 0.185) !important;
        input {
          display: none;
        }
      }
      .vgt-left-align, .vgt-right-align {
        background-color: rgba(255, 0, 0, 0.185) !important;
      }
    }
  }
</style>
